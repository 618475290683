import { push } from 'connected-react-router';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
import i18n from 'locales/i18n';

import { EAuthTypes } from 'models';
import { AuthService } from 'services/authService';
import {
    selectAuthUserToken,
    selectAuthUser,
    selectAuthUserCompany,
    selectAuthUserTimezone,
} from '../../selectors/authUser';
import { IUser, IUserFavourites } from 'models';
import {
    OAuthLogUser,
    authUpdateProfile,
    forgotPasswordActionSaga,
    loginActionSaga,
    loginActionSet,
    logoutActionSaga,
    logoutActionSet,
    registerActionSaga,
    resetPasswordActionSaga,
    setAuthUserAvatar,
    setUser,
    oAuthGenerateStateAction,
    updateAuthUserSaga,
    getAuthUserFavourites,
    setAuthUserFavourites,
    deleteAuthUserFavouriteUser,
    setAuthUserRegisteredPasswordAction,
    setAuthTourCurrentStepAction,
    patchAuthUserProfileAction,
    setAuthUserProfileAction,
    setCompanyAction,
    authRenewTokenSaga,
    setAppLoadingAction,
    resendAuthUserVerificationEmail,
    getSubscriptionAction,
    setSubscriptionAction,
} from 'store/actions';
import { updateToken, clearToken } from 'utils/axiosInstance/tokenInstance';
import { CompanyService, GiftAccountsService, PaymentsService } from 'services';
import { ICompany } from 'models';
import { setGiftAccountsAction } from 'store/actions/giftAccounts';

/**
 * Handle the login action of the user
 * @param {ReturnType<typeof loginActionSaga>} {payload}
 */
function* handleLogin({ payload }: ReturnType<typeof loginActionSaga>) {
    try {
        let data;
        if (!payload.skipToken) {
            const { data: userData } = yield AuthService.handleLogin(
                payload.token,
            );

            if (userData) data = userData;
        } else {
            data = payload.data;
        }

        if (data) {
            updateToken(data.token);
            let companyData: ICompany | null = null;
            if (data.user?.company_membership) {
                const { data: company } =
                    yield CompanyService.getCompanyByCompanyId({
                        companyId: data.user?.company_membership?.company,
                    });

                if (company) companyData = company;
                yield put(
                    setCompanyAction({
                        ...company,
                    }),
                );
            }

            const { data: giftAccounts } = yield GiftAccountsService.getGiftAccounts();


            yield put(setGiftAccountsAction({
                callbackOnError: () => { },
                callbackOnSuccess: () => { },
                giftAccounts: giftAccounts
            }));

            yield put(
                loginActionSet({
                    ...data,
                    user: {
                        ...data.user,
                        company_membership: {
                            ...companyData,
                            admin: data?.user?.company_membership?.admin,
                        },
                        profile: {
                            ...data.user.profile,
                            dark_mode:
                                data?.user?.profile?.dark_mode === null
                                    ? window.matchMedia(
                                        '(prefers-color-scheme: dark)',
                                    ).matches
                                        ? 'dark'
                                        : 'light'
                                    : data?.user?.profile?.dark_mode
                                        ? 'dark'
                                        : 'light',
                        },
                    },
                }),
            );
            yield put(
                getSubscriptionAction({
                    callbackOnSuccess: () => { },
                    callbackOnError: () => { },
                }),
            );
            yield put(
                setAuthUserRegisteredPasswordAction({
                    password: undefined,
                }),
            );
            payload?.callbackOnSuccess &&
                payload?.callbackOnSuccess({
                    profile_complete: data.user.profile_complete,
                    company: {
                        data: companyData,
                        isAdmin: data?.user?.company_membership?.admin,
                    },
                });
            if (data.user.profile) {
                const language = data.user.profile.language;
                yield i18n.changeLanguage(language || 'en');
            }
        }
    } catch (error: any) {
        console.log({ handleLogin: error });
        payload?.callbackOnError && payload?.callbackOnError();
    }
}

/**
 * Handle the register action of the user
 * @param {ReturnType<typeof registerActionSaga>} {payload}
 */
function* handleRegister({ payload }: ReturnType<typeof registerActionSaga>) {
    try {
        const { data } = yield AuthService.handleRegister(payload);
        if (data) {
            yield put(loginActionSet(data));
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
            yield put(
                setAuthUserRegisteredPasswordAction({
                    password: payload.reRegister ? '' : payload.password,
                }),
            );
        }
    } catch (error: any) {
        console.log({ error });
        payload?.callbackOnError && payload?.callbackOnError(error);
        Sentry.captureException(error);
    }
}

/**
 * Handle the logout action of the user
 * @param {ReturnType<typeof logoutActionSaga>} {payload}
 */
function* handleLogout({ payload }: ReturnType<typeof logoutActionSaga>) {
    try {
        yield AuthService.handleLogout();
        yield put(logoutActionSet());
        clearToken();
        yield put(push('/login'));
        payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        yield i18n.changeLanguage('en');
    } catch (error: any) {
        yield put(logoutActionSet());
        clearToken();
        yield put(push('/login'));
        console.log({ handleRegister: error });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof forgotPasswordActionSaga>} {
 *     payload,
 * }
 */
function* handleForgotPassword({
    payload,
}: ReturnType<typeof forgotPasswordActionSaga>) {
    try {
        const { data } = yield AuthService.handleForgotPassword({
            email: payload.email,
        });
        if (data && data.status === 'OK') {
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({ handleForgotPassword: error });
        payload?.callbackOnError && payload?.callbackOnError(error);
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof resetPasswordActionSaga>} {
 *     payload,
 * }
 */
function* handleResetPassword({
    payload,
}: ReturnType<typeof resetPasswordActionSaga>) {
    try {
        const { data } = yield AuthService.handleSetNewPassword(payload);
        if (data && data.status === 'OK') {
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({ handleResetPassword: error });
        payload?.callbackOnError && payload?.callbackOnError();
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof authUpdateProfile>} {payload}
 */
function* handleUpdateProfile({ payload }: ReturnType<typeof authUpdateProfile>) {
    try {
        if (
            payload.photo &&
            typeof payload.photo !== 'string' &&
            !payload.photo?.avatar
        ) {
            const { data: uploadAvatarData } = yield AuthService.uploadAvatar(
                payload.photo as File,
            );

            if (uploadAvatarData) {
                yield put(setAuthUserAvatar({ avatar: uploadAvatarData.avatar }));
            }
        }

        const { data } = yield AuthService.handleUpdateProfile({ ...payload });

        if (data) {
            if (data?.profile_complete) {
                yield put(
                    setUser({
                        ...data,
                    }),
                );
                payload.callbackOnSuccess && payload.callbackOnSuccess();
                yield put(push('/'));
            } else {
                const t: string = yield i18n.t(
                    'completeProfile.yourProfileIsIncomplete',
                );
                payload?.callbackOnError &&
                    payload?.callbackOnError({
                        message: t,
                    });
            }
        } else {
            Sentry.captureException(
                'No data in response while completing the user profile',
            );
            const t: string = yield i18n.t('common.anErrorHasOccurred');
            payload?.callbackOnError &&
                payload?.callbackOnError({
                    message: t,
                });
        }
    } catch (error: any) {
        const t: string = yield i18n.t('common.anErrorHasOccurred');
        payload?.callbackOnError &&
            payload?.callbackOnError({
                message: t,
            });
        console.log({ handleUpdateProfile: error });
        Sentry.captureException(error);
    }
}

function* getAuthUser() {
    try {
        const authUser: IUser = yield select(selectAuthUser);
        if (!authUser) {
            const { data } = yield AuthService.handleUserInfo();
            if (data) {
                yield put(
                    setUser({
                        ...data,
                        profile: {
                            ...data,
                            dark_mode:
                                data?.profile?.dark_mode === null ||
                                    data?.user?.profile?.dark_mode === null
                                    ? window.matchMedia(
                                        '(prefers-color-scheme: dark)',
                                    ).matches
                                        ? 'dark'
                                        : 'light'
                                    : data?.user?.profile?.dark_mode
                                        ? 'dark'
                                        : 'light',
                        },
                    }),
                );
            }
        }
    } catch (error: any) {
        console.log({ getAuthUser: error });
        Sentry.captureException(error);
    }
}

/**
 * Handle User auth action
 * @param {ReturnType<typeof OAuthLogUser>} {payload}
 */
function* handleOAuth({ payload }: ReturnType<typeof OAuthLogUser>) {
    try {
        const { data: validateRequest } = yield AuthService.oAuthValidateState(
            payload?.provider,
            payload?.state,
        );

        if (validateRequest?.valid) {
            updateToken(payload.token);
            const { data } = yield AuthService.handleRenewToken();

            if (data) {
                let companyData: ICompany | null = null;
                if (data.user?.company_membership) {
                    const { data: company } =
                        yield CompanyService.getCompanyByCompanyId({
                            companyId: data.user?.company_membership.company,
                        });

                    if (company) companyData = company;
                    yield put(
                        setCompanyAction({
                            ...company,
                        }),
                    );
                }
                yield put(
                    loginActionSet({
                        expiry: data.expiry,
                        token: payload.token,
                        user: {
                            ...data.user,
                            company_membership: {
                                ...companyData,
                                admin: data?.user?.company_membership?.admin,
                            },
                            profile: {
                                ...data?.user?.profile,
                                dark_mode:
                                    data?.user?.profile?.dark_mode === null
                                        ? window.matchMedia(
                                            '(prefers-color-scheme: dark)',
                                        ).matches
                                            ? 'dark'
                                            : 'light'
                                        : data?.user?.profile?.dark_mode
                                            ? 'dark'
                                            : 'light',
                            },
                        },
                    }),
                );
                if (
                    !companyData?.address &&
                    data?.user?.company_membership?.admin
                ) {
                    yield put(push('/companies/complete-organization'));
                } else if (!data?.user?.profile_complete) {
                    yield put(push('/complete-profile'));
                } else {
                    yield put(push('/'));
                }
                localStorage.removeItem(
                    process.env.REACT_APP_OAUTH_STATE as string,
                );
            } else {
                yield put(push('/login'));
            }
        } else {
            yield put(push('/login'));
        }
    } catch (error: any) {
        console.log({ handleOAuth: error });
        Sentry.captureException(error);
    }
}

/**
 * Renew User token or logout the user
 * @param {ReturnType<typeof authRenewTokenSaga>} {payload}
 */
function* handleRenewToken({ payload }: ReturnType<typeof authRenewTokenSaga>) {
    try {
        if (!payload.checkIfTokenStillValid)
            yield put(setAppLoadingAction(true));

        const selectToken: string = yield select(selectAuthUserToken);
        updateToken(selectToken);
        const { data } = yield AuthService.handleRenewToken();

        if (data?.user?.profile) {
            const language = data.user.profile.language;
            if (i18n.language !== language)
                yield i18n.changeLanguage(language || 'en');
        } else {
            yield i18n.changeLanguage('en')
        }


        if (data && data.valid) {
            const { data: giftAccounts } = yield GiftAccountsService.getGiftAccounts();

            yield put(setGiftAccountsAction({
                callbackOnError: () => { },
                callbackOnSuccess: () => { },
                giftAccounts: giftAccounts
            }));

            if (!payload.checkIfTokenStillValid) {
                let companyData: ICompany | null = null;
                if (data.user?.company_membership) {
                    const { data: company } =
                        yield CompanyService.getCompanyByCompanyId({
                            companyId: data.user?.company_membership.company,
                        });

                    if (company) companyData = company;
                    yield put(
                        setCompanyAction({
                            ...company,
                        }),
                    );
                }

                yield put(
                    loginActionSet({
                        expiry: data.expiry,
                        token: selectToken,
                        user: {
                            ...data.user,
                            company_membership: {
                                ...companyData,
                                admin: data?.user?.company_membership?.admin,
                            },
                            profile: {
                                ...data?.user?.profile,
                                dark_mode:
                                    data?.user?.profile?.dark_mode === null
                                        ? window.matchMedia(
                                            '(prefers-color-scheme: dark)',
                                        ).matches
                                            ? 'dark'
                                            : 'light'
                                        : data?.user?.profile?.dark_mode
                                            ? 'dark'
                                            : 'light',
                            },
                        },
                    }),
                );
                yield put(
                    setAuthUserRegisteredPasswordAction({
                        password: undefined,
                    }),
                );

                const { data: subscriptions } = yield PaymentsService.getSubscriptions({
                    status: 'active',
                });
                if (subscriptions.length > 0) {
                    const { data: subscription } = yield PaymentsService.getSubscription({
                        subscriptionId: subscriptions[0].id,
                    });

                    if (subscription) {
                        yield put(setSubscriptionAction(subscription))
                    }
                }
            }
            yield put(setAppLoadingAction(false));
        } else {
            payload.callbackOnError && payload.callbackOnError();
            // ** Logout user
            yield put(logoutActionSet());
            clearToken();
            yield put(setAppLoadingAction(false));
        }
    } catch (error: any) {
        payload.callbackOnError && payload.callbackOnError();
        // ** Logout user
        yield put(logoutActionSet());
        clearToken();
        console.log({ handleRenewToken: error });
        Sentry.captureException(error);
        yield put(setAppLoadingAction(false));
    }
}
/**
 * Handle the tour completed action
 */
function* handleTourCompleted() {
    try {
        const authUserTimezone: string = yield select(selectAuthUserTimezone);
        const now = moment
            .tz(
                new Date(
                    new Date().toLocaleString('en', {
                        timeZone:
                            authUserTimezone ||
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }),
                ),
                authUserTimezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            )
            .toDate();
        const { data } = yield AuthService.handleUpdateProfileTourCompleted({
            tour_complete: now,
        });

        if (data) {
            const { data: profile } =
                yield AuthService.handleGetAuthUserProfile();

            if (profile) {
                yield put(
                    setUser({
                        ...data,
                        profile: {
                            ...profile,
                            dark_mode:
                                profile?.dark_mode === null
                                    ? window.matchMedia(
                                        '(prefers-color-scheme: dark)',
                                    ).matches
                                        ? 'dark'
                                        : 'light'
                                    : profile?.dark_mode
                                        ? 'dark'
                                        : 'light',
                        },
                    }),
                );
                yield put(setAuthTourCurrentStepAction(undefined));
            }
        }
    } catch (error) {
        console.log({ handleTourCompleted: error });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof oAuthGenerateStateAction>} {
 *     payload,
 * }
 */
function* handleOAuthGenerateState({
    payload,
}: ReturnType<typeof oAuthGenerateStateAction>) {
    try {
        const { data } = yield AuthService.oAuthGenerateState(payload.provider);

        if (data) {
            localStorage.setItem(
                process.env.REACT_APP_OAUTH_STATE as string,
                `${data.oauth_provider}|${data.fingerprint}`,
            );
            payload?.callbackOnSuccess && payload.callbackOnSuccess();
        } else {
            const e: string = yield i18n.t(
                'login.weAreSorryLooksLikeWeAreHavingSomeTechnicalDifficulties',
            );
            payload.callbackOnError && payload.callbackOnError(e);
        }
    } catch (error: any) {
        console.log({ error });
        const e: string = yield i18n.t(
            'login.weAreSorryLooksLikeWeAreHavingSomeTechnicalDifficulties',
        );
        payload.callbackOnError && payload.callbackOnError(e);
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof updateAuthUserSaga>} {
 *     payload,
 * }
 */
function* handleUpdateAuthUser({
    payload,
}: ReturnType<typeof updateAuthUserSaga>) {
    try {
        const selectAuthUserData: IUser = yield select(selectAuthUser);
        const authUserTimezone: string = yield select(selectAuthUserTimezone);

        if (!selectAuthUserData) {
            throw new Error();
        } else {
            const authUserCompanyMembership: ICompany = yield select(
                selectAuthUserCompany,
            );

            const datePreference = [
                'DD-MM-YYYY',
                'MM-DD-YYYY',
                'YYYY-MM-DD',
                'DD/MM/YYYY',
                'MM/DD/YYYY',
                'YYYY/MM/DD',
                'Do MMM YYYY',
                'Do MMMM, YYYY',
                'dddd, Do MMMM, YYYY',
            ];
            const timePreference = ['h:mm A', 'HH:mm'];

            const { data } = yield AuthService.updateAuthUser({
                user: {
                    ...selectAuthUserData.user,
                    gender: selectAuthUserData?.user?.gender
                        ? selectAuthUserData?.user?.gender
                        : undefined,
                    profile_url: selectAuthUserData?.user?.profile_url
                        ? selectAuthUserData?.user?.profile_url
                        : undefined,
                    ...(payload.data.user as IUser),
                    timezone: payload.data.user?.timezone
                        ? payload.data.user?.timezone
                        : authUserTimezone ||
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    datetime_preference: {
                        date:
                            datePreference.indexOf(
                                payload.data.user?.datetime_preference
                                    ?.date as string,
                            ) === -1
                                ? 'DD-MM-YYYY'
                                : (payload.data.user?.datetime_preference
                                    ?.date as string),
                        time:
                            timePreference.indexOf(
                                payload.data.user?.datetime_preference
                                    ?.time as string,
                            ) === -1
                                ? 'HH:mm'
                                : (payload.data.user?.datetime_preference
                                    ?.time as string),
                    },
                },
            });

            if (data) {
                yield put(
                    loginActionSet({
                        expiry: selectAuthUserData.expiry,
                        token: selectAuthUserData.token,
                        user: {
                            ...data,
                            id: selectAuthUserData?.user?.id,
                            email: selectAuthUserData?.user?.email,
                            created: selectAuthUserData?.user?.created,
                            avatar: selectAuthUserData?.user?.avatar,
                            rating: selectAuthUserData?.user?.rating,
                            company_membership: authUserCompanyMembership,
                            profile: {
                                ...selectAuthUserData.user?.profile,
                                first_name: payload.data.user?.first_name,
                                last_name: payload.data.user?.last_name,
                                gender: payload.data.user?.gender,
                                profile_url: payload.data.user?.profile_url,
                                dark_mode:
                                    selectAuthUserData?.user?.profile
                                        ?.dark_mode,
                            },
                        },
                    }),
                );
                payload.callbackOnSuccess && payload.callbackOnSuccess();
            }
        }
    } catch (error: any) {
        console.log({ handleUpdateAuthUser: error });
        payload.callbackOnError && payload.callbackOnError(error);
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof getAuthUserFavourites>} {
 *     payload,
 * }
 */
function* handleGetAuthUserFavourites({
    payload,
}: ReturnType<typeof getAuthUserFavourites>) {
    try {
        const { data }: { data: IUserFavourites[] } =
            yield AuthService.getAuthUserFavorites();

        if (data) {
            yield put(setAuthUserFavourites({ favourites: data }));
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({ handleGetAuthUserFavourites: error });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof deleteAuthUserFavouriteUser>} {
 *     payload,
 * }
 */
function* handleDeleteAuthUserFavouriteUser({
    payload,
}: ReturnType<typeof deleteAuthUserFavouriteUser>) {
    try {
        if (!payload.favouriteUserId) {
            throw new Error('Parameter missing.');
        }

        yield AuthService.deleteAuthUserFavourite({
            favouriteUserId: payload.favouriteUserId,
        });

        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        console.log({ handleDeleteAuthUserFavouriteUser: error });
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError && payload.callbackOnError(e);
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof patchAuthUserProfileAction>} {
 *     payload,
 * }
 */
function* handlePatchAuthUserProfileSaga({
    payload,
}: ReturnType<typeof patchAuthUserProfileAction>) {
    try {
        const { data } = yield AuthService.handlePatchAuthUserProfile({
            ...payload,
        });

        if (data) {
            yield put(
                setAuthUserProfileAction({
                    ...data,
                    dark_mode: data.dark_mode
                        ? 'dark'
                        : data.dark_mode === null
                            ? 'dark'
                            : 'light',
                }),
            );
        }
    } catch (error) {
        console.log({ handlePatchAuthUserProfileSaga: error });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof resendAuthUserVerificationEmail>} {
 *     payload,
 * }
 */
function* handleResendVerificationEmail({
    payload,
}: ReturnType<typeof resendAuthUserVerificationEmail>) {
    try {
        const { data } = yield AuthService.resendVerificationEmail({
            url: payload.url,
        });
        payload.callbackOnSuccess && payload.callbackOnSuccess({ data });
        console.log({ data });
    } catch (error) {
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError && payload.callbackOnError(e);
        console.log({ handleResendVerificationEmail: error });
        Sentry.captureException(error);
    }
}

/**
 * @export
 * @return {*}  {Generator<unknown, any, undefined>}
 */
export function* AuthSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(EAuthTypes.AUTH_LOGIN_ACTION_SAGA, handleLogin),
        takeLatest(EAuthTypes.AUTH_REGISTER_ACTION_SAGA, handleRegister),
        takeLatest(EAuthTypes.AUTH_LOGOUT_ACTION_SAGA, handleLogout),
        takeLatest(EAuthTypes.AUTH_FORGOT_PASSWORD, handleForgotPassword),
        takeLatest(EAuthTypes.AUTH_RESET_PASSWORD, handleResetPassword),
        takeLatest(EAuthTypes.AUTH_UPDATE_PROFILE_SAGA, handleUpdateProfile),
        takeLatest(EAuthTypes.AUTH_GET_USER_SAGA, getAuthUser),
        takeLatest(EAuthTypes.OAUTH_LOG_USER_SAGA, handleOAuth),
        takeLatest(EAuthTypes.AUTH_RENEW_TOKEN_SAGA, handleRenewToken),
        takeLatest(EAuthTypes.AUTH_TOUR_COMPLETED_SAGA, handleTourCompleted),
        takeLatest(
            EAuthTypes.OAUTH_GENERATE_STATE_SAGA,
            handleOAuthGenerateState,
        ),
        takeLatest(EAuthTypes.UPDATE_AUTH_USER_SAGA, handleUpdateAuthUser),
        takeLatest(
            EAuthTypes.GET_AUTH_USER_FAVOURITES_SAGA,
            handleGetAuthUserFavourites,
        ),
        takeLatest(
            EAuthTypes.DELETE_AUTH_USER_FAVOURITE_USER_SAGA,
            handleDeleteAuthUserFavouriteUser,
        ),
        takeLatest(
            EAuthTypes.AUTH_USER_PATCH_PROFILE_SAGA,
            handlePatchAuthUserProfileSaga,
        ),
        takeLatest(
            EAuthTypes.AUTH_RESEND_VERIFICATION_EMAIL_ACTION_SAGA,
            handleResendVerificationEmail,
        ),
    ]);
}
