import { AxiosResponse } from 'axios';

import * as apiRequest from 'utils/axiosInstance/basicInstance';
import tokenInstance from 'utils/axiosInstance/tokenInstance';
import { IUserData } from 'models';
import { TGender } from '../models/auth/types';

const handleLogin = (token: string): Promise<AxiosResponse<any, any>> => {
    const requestInstance = apiRequest.basicInstance(token);
    return requestInstance.post('auth/login/');
};

const handleRegister = (data: {
    password: string;
    password2: string;
    email: string;
    first_name: string;
    last_name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('auth/register/', data);
};

const uploadAvatar = (avatar: File): Promise<AxiosResponse<any, any>> => {
    const formData = new FormData();
    formData.append('avatar', avatar as File);
    return tokenInstance().post('users/me/avatar', formData);
};

const handleGetAuthUserProfile = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('/users/me/profile');
};

const handleUpdateProfile = (data: {
    first_name: string;
    last_name: string;
    timezone: string;
    photo?: File;
    timezone_verified: Date;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch('users/me', data);
};

const deleteAccount = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete('users/me');
}

const handlePatchAuthUserProfile = (data: {
    gender?: TGender;
    my_focus?: string;
    profile_url?: string;
    dark_mode?: boolean;
    language?: 'en' | 'ro' | 'es' | 'pt';
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch('users/me/profile', data);
};
const handleUpdateProfileTourCompleted = (data: {
    tour_complete: Date;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch('users/me', data);
};

const handleForgotPassword = (data: {
    email: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('auth/password_reset/', data);
};

const handleChangePassword = ({
    oldPassword,
    newPassword,
}: {
    oldPassword: string;
    newPassword: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch('auth/change_password', {
        old_password: oldPassword,
        new_password: newPassword,
    });
};

const handleTokenValidity = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('auth/password-forgot/check-token-validity/');
};

const handleSetNewPassword = (data: {
    password: string;
    password_confirm: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('auth/password_reset/confirm/', data);
};

const handleLogout = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('auth/logout/');
};

const handleMetaData = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('metadata/');
};

const handleUserInfo = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('users/me');
};

const handleBetaUser = (data: any): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('beta-users/', data);
};

const handleRenewToken = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`auth/renew-token`);
};

export const oAuthGenerateState = (
    provider: 'google' | 'facebook',
): Promise<AxiosResponse<any, any>> => {
    return apiRequest.basicInstance().get(`/auth/state/generate/${provider}`);
};

export const oAuthValidateState = (
    provider: 'google' | 'facebook',
    state: string,
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/auth/state/validate/${provider}`, { state });
};

export const updateAuthUser = ({
    user,
}: {
    user: IUserData;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/users/me`, { ...user });
};

export const getAuthUserFavorites = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/users/me/favourites/`);
};

export const deleteAuthUserFavourite = ({
    favouriteUserId,
}: {
    favouriteUserId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`/users/favourite/${favouriteUserId}`);
};

export const getAuthUserReports = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/user-reports/abuse-reports`);
};

export const postVerifyEmail = ({
    k,
}: {
    k: string;
}): Promise<AxiosResponse<any, any>> => {
    return apiRequest.basicInstance().post(`/users/me/confirm-email?k=${k}`);
};

export const getUserIgnores = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/users/me/ignores/`);
};

export const postUserIgnores = ({
    userId,
}: {
    userId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/users/ignore/${userId}`);
};

export const deleteUserIgnores = ({
    userId,
}: {
    userId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`/users/ignore/${userId}`);
};

export const getUserById = ({
    id,
}: {
    id: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/users/${id}`);
};

const resendVerificationEmail = ({
    url,
}: {
    url: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`${url}`);
};

const getAuthUserEmailPreferences = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/users/me/email-preferences`);
};

const patchAuthUserEmailPreferences = ({
    new_session_from_favourite_user,
    performance_report,
    marketing,
    educational
}: {
    new_session_from_favourite_user: boolean;
    performance_report: boolean;
    marketing: boolean;
    educational: boolean
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/users/me/email-preferences`, {
        new_session_from_favourite_user,
        performance_report,
        marketing,
        educational
    });
};

export const AuthService = {
    handleLogin,
    handleRegister,
    handleUpdateProfile,
    handleForgotPassword,
    handleChangePassword,
    handleTokenValidity,
    handleSetNewPassword,
    handleLogout,
    handleMetaData,
    handleUserInfo,
    handleBetaUser,
    uploadAvatar,
    handleRenewToken,
    handleUpdateProfileTourCompleted,
    oAuthGenerateState,
    oAuthValidateState,
    updateAuthUser,
    getAuthUserFavorites,
    deleteAuthUserFavourite,
    getAuthUserReports,
    handlePatchAuthUserProfile,
    handleGetAuthUserProfile,
    postVerifyEmail,
    getUserIgnores,
    postUserIgnores,
    deleteUserIgnores,
    resendVerificationEmail,
    getAuthUserEmailPreferences,
    patchAuthUserEmailPreferences,
    deleteAccount
};
