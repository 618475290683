import {TStore} from 'models';

export const selectCreateOrUpdateTodoModal = (state: TStore) =>
    state?.todo?.createOrEditModalOpened;

export const selectTodos = (state: TStore) => state?.todo?.data;

export const selectEditableTodo = (state: TStore) => state?.todo?.editableTodo;

export const selectFinishPomodoroRoundEarly = (state: TStore) =>
    state?.todo?.finishPomodoroRoundEarly;

export const selectPickNewTaskWhileOneRunning = (state: TStore) =>
    state?.todo?.pickNewTaskWhileOneRunning;

export const selectPomodoroTimerRunning = (state: TStore) =>
    state?.todo?.isPomodoroTimerOn;

export const selectPomodoroCurrentTask = (state: TStore) =>
    state?.todo?.currentTask;

export const selectNoOfPomodorosFinished = (state: TStore) =>
    state?.todo?.noOfPomodorosFinished;

export const selectActivePomodoroTab = (state: TStore) =>
    state?.todo?.activePomodoroTab;

export const selectShowPomodoroWidget = (state: TStore) =>
    state?.todo?.showPomodoroWidget;
