import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import crypto from 'crypto';
import { KnownVisibilityStates } from 'react-page-visibility';

import {
    selectShowPomodoroWidget,
    useAppDispatch,
    useAppSelector,
    setAppVisibilityAction,
    selectAuthUserToken,
    authRenewTokenSaga,
    selectAuthUserEmail,
    selectAuthUserFullName,
    selectAuthUserProfileComplete,
    selectAuthUserTourComplete,
    selectAuthUserMessage,
} from 'store';

declare global {
    interface Window {
        Tawk_API: any;
    }
}

const stateSelectorHandle = createSelector(
    selectShowPomodoroWidget,
    selectAuthUserToken,
    selectAuthUserEmail,
    selectAuthUserFullName,
    selectAuthUserProfileComplete,
    selectAuthUserTourComplete,
    selectAuthUserMessage,
    (
        showPomodoroWidget,
        authUserToken,
        authUserEmail,
        authUserFullName,
        authUserProfileComplete,
        authUserTourComplete,
        authUserMessage,
    ) => {
        return {
            showPomodoroWidget,
            authUserToken,
            authUserEmail,
            authUserFullName,
            authUserProfileComplete,
            authUserTourComplete,
            authUserMessage,
        };
    },
);

export const useLayout = () => {
    const stateSelector = useCallback(stateSelectorHandle, []);
    const tawkMessengerRef = useRef<any>();
    const {
        showPomodoroWidget,
        authUserToken,
        authUserEmail,
        authUserFullName,
        authUserProfileComplete,
        authUserTourComplete,
        authUserMessage,
    } = useAppSelector(stateSelector);
    const location = useLocation();
    const dispatch = useAppDispatch();

    /**
     * Handle window visibility change
     * @param {boolean} isVisible
     * @param {KnownVisibilityStates} visibilityState
     */
    const handleVisibilityChange = useCallback(

        (isVisible: boolean, visibilityState: KnownVisibilityStates) => {
            dispatch(
                setAppVisibilityAction({
                    isVisible,
                    visibilityState,
                }),
            );
            if (authUserToken && isVisible)
                dispatch(authRenewTokenSaga({ checkIfTokenStillValid: true }));
        },
        [authUserToken, dispatch],
    );

    useEffect(() => {
        try {
            const tawkto = window?.Tawk_API;

            if (authUserToken && authUserEmail && authUserFullName) {
                const hash = crypto
                    .createHmac(
                        'sha256',
                        process.env.REACT_APP_TAWKTO_JS_API_KEY || '',
                    )
                    .update(authUserEmail || '')
                    .digest('hex');

                tawkto.onLoad = function () {
                    tawkto.setAttributes(
                        {
                            name: authUserFullName,
                            email: authUserEmail,
                            hash: hash,
                        },
                        function (error: any) {
                            console.error({ error });
                        },
                    );
                };
            }
        } catch (error) {
            console.error({ error })
        }
    }, [authUserEmail, authUserFullName, authUserToken, location.pathname]);

    return {
        tawkMessengerRef,
        handleVisibilityChange,
        authUserToken,
        authUserProfileComplete,
        authUserTourComplete,
        location,
        showPomodoroWidget,
        authUserMessage,
    };
};
