import {memo, ReactElement, SetStateAction, Dispatch} from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const DropDownComponent = memo(
    ({
        startAdornment,
        IconComponent,
        matches,
        labelClassName,
        inputPropsClassName,
        menuItems,
        label,
        emLabel,
        displayEmpty,
        labelId,
        value,
        sx,
        setOptions,
        customKey,
        disabled = false,
    }: {
        startAdornment: ReactElement<any, any>;
        IconComponent: ReactElement<any, any>;
        matches: boolean;
        labelClassName?: string;
        inputPropsClassName?: string;
        menuItems: any[];
        label: string;
        emLabel: string;
        displayEmpty: boolean;
        labelId: string;
        value: string | undefined;
        sx: Record<string, unknown>;
        setOptions: Dispatch<
            SetStateAction<{
                videoInput?: string;
                sendResolution?: string;
                receiveResolution?: string;
                audioInput?: string;
                speakers?: string;
                noiseSuppression?: string;
            }>
        >;
        customKey: string;
        disabled?: boolean;
    }) => {
        return (
            <>
                <InputLabel id={labelId} className={labelClassName}>
                    {label}
                </InputLabel>
                <Select
                    disabled={!!disabled}
                    variant="standard"
                    labelId={labelId}
                    displayEmpty={displayEmpty}
                    // onOpen={() => {
                    //     setDropDownMenuOpen(keyName);
                    // }}
                    // onClose={() => setDropDownMenuOpen('')}
                    sx={sx}
                    inputProps={{
                        className: inputPropsClassName,
                    }}
                    onChange={(e: any) => {
                        setOptions(
                            (prev: {
                                videoInput?: string;
                                sendResolution?: string;
                                receiveResolution?: string;
                                audioInput?: string;
                                speakers?: string;
                                noiseSuppression?: string;
                            }) => ({
                                ...prev,
                                [customKey]: e.target.value,
                            }),
                        );
                    }}
                    IconComponent={() => IconComponent}
                    value={value}
                    // error={error}
                    startAdornment={startAdornment}>
                    <MenuItem
                        disabled
                        value=""
                        sx={{
                            display: 'none',
                            backgroundColor: '#fff',
                            fontFamily: 'Brandon Grotesque',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: 20,
                            color: 'var(--theme-label-color)',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                color: '#fff',
                            },
                        }}>
                        <em>{emLabel}</em>
                    </MenuItem>
                    {menuItems.map((menuItem: any, index: number) => (
                        <MenuItem
                            key={`menu-item-${menuItem.value}-${menuItem.label}-${index}`}
                            value={menuItem.value}>
                            {menuItem.label}
                        </MenuItem>
                    ))}
                </Select>
            </>
        );
    },
);
