import {
    ICompanyAddress,
    IAuthUserProfile,
    EAuthTypes,
    IUser,
    IUserFavourites,
    TGender,
    ICompany,
} from 'models';

export const loginActionSaga = (payload: {
    token: string;
    callbackOnSuccess: ({
        profile_complete,
        company,
    }: {
        profile_complete: boolean;
        company: {
            data: ICompany | null;
            isAdmin: boolean;
        };
    }) => void;
    callbackOnError: () => void;
    skipToken?: boolean;
    data?: any;
}) => ({
    type: EAuthTypes.AUTH_LOGIN_ACTION_SAGA,
    payload,
});

export const loginActionSet = (payload: IUser) => ({
    type: EAuthTypes.AUTH_LOGIN_ACTION_SET,
    payload,
});

export const loginActionSetCompanyAddress = (payload: ICompanyAddress) => ({
    type: EAuthTypes.AUTH_SET_COMPANY_ADDRESS,
    payload,
});
export const registerActionSaga = (payload: {
    password: string;
    password2: string;
    email: string;
    first_name: string;
    last_name: string;
    callbackOnSuccess: () => void;
    callbackOnError: (error: any) => void;
    reRegister?: boolean;
    register_company: boolean;
    company_name?: string;
}) => ({
    type: EAuthTypes.AUTH_REGISTER_ACTION_SAGA,
    payload,
});

export const forgotPasswordActionSaga = (payload: {
    email: string;
    callbackOnSuccess: () => void;
    callbackOnError: (error: any) => void;
}) => ({
    type: EAuthTypes.AUTH_FORGOT_PASSWORD,
    payload,
});

export const resetPasswordActionSaga = (payload: {
    token: string;
    password: string;
    password_confirm: string;
    callbackOnSuccess: () => void;
    callbackOnError: () => void;
}) => ({
    type: EAuthTypes.AUTH_RESET_PASSWORD,
    payload,
});

export const authUpdateProfile = (payload: {
    first_name: string;
    last_name: string;
    timezone: string;
    photo?: any;
    timezone_verified: Date;
    profile_url: string;
    callbackOnSuccess: () => void;
    callbackOnError?: ({ message }: { message: string }) => void;
}) => ({
    type: EAuthTypes.AUTH_UPDATE_PROFILE_SAGA,
    payload,
});

export const authGetUser = () => ({
    type: EAuthTypes.AUTH_GET_USER_SAGA,
});

export const logoutActionSaga = (payload?: {
    callbackOnSuccess?: () => void;
}) => ({
    type: EAuthTypes.AUTH_LOGOUT_ACTION_SAGA,
    payload,
});

export const setUser = (payload: IUser) => ({
    type: EAuthTypes.AUTH_SET_USER,
    payload,
});

export const logoutActionSet = () => ({
    type: EAuthTypes.AUTH_LOGOUT_ACTION_SET,
});

export const setAuthUserAvatar = (payload: { avatar: string }) => ({
    type: EAuthTypes.AUTH_SET_USER_AVATAR,
    payload,
});

export const OAuthLogUser = (payload: {
    token: string;
    provider: 'google' | 'facebook';
    state: string;
}) => ({
    type: EAuthTypes.OAUTH_LOG_USER_SAGA,
    payload,
});

export const authRenewTokenSaga = (payload: {
    checkIfTokenStillValid: boolean;
    callbackOnError?: () => void;
}) => ({
    type: EAuthTypes.AUTH_RENEW_TOKEN_SAGA,
    payload,
});

export const tourCompletedSaga = () => ({
    type: EAuthTypes.AUTH_TOUR_COMPLETED_SAGA,
});

export const oAuthGenerateStateAction = (payload: {
    provider: 'google' | 'facebook';
    callbackOnSuccess: () => void;
    callbackOnError: (message: string) => void;
}) => ({
    type: EAuthTypes.OAUTH_GENERATE_STATE_SAGA,
    payload,
});

export const updateAuthUserSaga = (payload: {
    data: IUser;
    callbackOnSuccess: () => void;
    callbackOnError: (message: string) => void;
}) => ({
    type: EAuthTypes.UPDATE_AUTH_USER_SAGA,
    payload,
});

export const getAuthUserFavourites = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: (message: string) => void;
}) => ({
    type: EAuthTypes.GET_AUTH_USER_FAVOURITES_SAGA,
    payload,
});

export const setAuthUserFavourites = (payload: {
    favourites: IUserFavourites[];
}) => ({
    type: EAuthTypes.SET_AUTH_USER_FAVOURITES,
    payload,
});

export const deleteAuthUserFavouriteUser = (payload: {
    favouriteUserId: number;
    callbackOnSuccess?: () => void;
    callbackOnError?: (message: string) => void;
}) => ({
    type: EAuthTypes.DELETE_AUTH_USER_FAVOURITE_USER_SAGA,
    payload,
});

export const setAuthUserRegisteredPasswordAction = (payload: {
    password: string | undefined;
}) => ({
    type: EAuthTypes.SET_AUTH_USER_REGISTERED_PASSWORD,
    payload,
});

export const setAuthTourCurrentStepAction = (payload: number | undefined) => ({
    type: EAuthTypes.AUTH_SET_TOUR_CURRENT_STEP,
    payload,
});

export const patchAuthUserProfileAction = (payload: {
    gender?: TGender;
    my_focus?: string;
    profile_url?: string;
    dark_mode?: boolean;
    sidebar_open?: boolean;
    language?: 'en' | 'ro' | 'es' | 'pt';
}) => ({
    type: EAuthTypes.AUTH_USER_PATCH_PROFILE_SAGA,
    payload,
});

export const setAuthUserProfileAction = (payload: IAuthUserProfile) => ({
    type: EAuthTypes.AUTH_USER_SET_PROFILE_ACTION,
    payload,
});

export const setIsWelcomeVideoOpenAction = (payload: boolean) => ({
    type: EAuthTypes.SET_IS_WELCOME_VIDEO_OPEN,
    payload,
});

export const resendAuthUserVerificationEmail = (payload: {
    url: string;
    callbackOnSuccess?: (data: any) => void;
    callbackOnError?: (message: string) => void;
}) => ({
    type: EAuthTypes.AUTH_RESEND_VERIFICATION_EMAIL_ACTION_SAGA,
    payload,
});

export const setShowNewSessionModal = (payload: boolean) => ({
    type: EAuthTypes.AUTH_SHOW_NEW_SESSION_MODAL_ACTION,
    payload,
});

export const setAuthUserIsPremiumAction = (payload: boolean) => ({
    type: EAuthTypes.SET_AUTH_USER_IS_PREMIUM_ACTION,
    payload
})