import {
    ISetWSReconnectIntervalAction,
    ISetWSReconnectAttemptsAction,
    ISetGlobalWSEventAction,
} from './actions';

export enum EWSTypes {
    'chat_message',
    'chat_history',
    'system_notification',
}

export enum EWSErrorTypes {
    'ws_error_code_99' = 'Internal Server Error',
    'ws_error_code_100' = 'Event not supported',
    'ws_error_code_101' = 'Received data invalid. Requires data and type',
    'ws_error_code_102' = 'Invalid or missing authentication',
    'ws_error_code_103' = 'No permission to perform this action',
    'ws_error_code_104' = 'Session does not exist',
    'ws_error_code_105' = 'You are not a participant in this session',
    'ws_error_code_106' = 'You are too early / late for session',
    'ws_error_code_107' = 'Error reading / writing to cache',
}

export type TWSLocations = 'LOBBY' | 'SESSION';

export enum EWSActionTypes {
    SET_WS_RECONNECT_INTERVAL = 'SET/WS/RECONNECT/INTERVAL',
    SET_WS_RECONNECT_ATTEMPTS = 'SET/WS/RECONNECT/ATTEMPTS',
    SET_WS_EVENT = 'SET/WS/EVENT',
}

export interface IVideoCallChatMessage {
    focus_session_id?: number;
    from_peer?: {
        last_name: string;
        first_name: string;
    };
    message: string;
    sent_at: Date;
}

export interface IWSState {
    data: {
        reconnectInterval: number;
        reconnectAttempts: number;
    };
    events:
        | {
              type: EWSTypes;
              data: Record<string, unknown>;
          }[]
        | undefined;
    lastEvent:
        | {
              type: EWSTypes;
              data: Record<string, unknown>;
          }
        | undefined;
}

export type TWSActionTypes =
    | ISetWSReconnectIntervalAction
    | ISetWSReconnectAttemptsAction
    | ISetGlobalWSEventAction;
