import {memo} from 'react';

export const SubscriptionIcon = memo(
    ({
        productId,
        width = 40,
        height = 40,
    }: {
        productId: string;
        width?: number;
        height?: number;
    }) => {
        return (
            <>
                {productId === process.env.REACT_APP_PREMIUM_PRODUCT_ID ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={width}
                        height={height}
                        viewBox="0 0 72 72"
                        fill="none">
                        <rect width="72" height="72" rx="16" fill="#FF9A0033" />
                        <rect
                            x="17"
                            y="17"
                            width="18.4865"
                            height="38"
                            fill="#FF9A0033"
                        />
                        <rect
                            x="35.4863"
                            y="17"
                            width="19.5135"
                            height="38"
                            fill="#FFFBF5"
                        />
                        <rect
                            x="35.4863"
                            y="35.4863"
                            width="19.5135"
                            height="19.5135"
                            fill="#FF9A00"
                        />
                    </svg>
                ) : productId === process.env.REACT_APP_BUSINESS_PRODUCT_ID ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={width}
                        height={height}
                        viewBox="0 0 72 72"
                        fill="none">
                        <rect width="72" height="72" rx="16" fill="#FF9A0033" />
                        <path
                            d="M57.1639 46.6464L57.1732 46.6413H57.1551L46.5288 40.8833L35.8849 35.1152L25.2405 40.8833L14.6143 46.6413H14.5962L14.6054 46.6464L14.5962 46.6515H14.6143L25.2405 52.4094L35.8849 58.1775L46.5288 52.4094L57.1551 46.6515H57.1732L57.1639 46.6464Z"
                            fill="#FF9A00"
                        />
                        <path
                            d="M57.1639 24.5312L57.1732 24.5265H57.1551L46.5288 18.7681L35.8849 13L25.2405 18.7681L14.6143 24.5265H14.5962L14.6054 24.5312L14.5962 24.5362H14.6143L25.2405 30.2946L35.8849 36.0627L46.5288 30.2946L57.1551 24.5362H57.1732L57.1639 24.5312Z"
                            fill="#FFFBF5"
                        />
                        <path
                            d="M14.6143 24.0625V47.1151L35.8849 35.589L14.6143 24.0625Z"
                            fill="#FF9A0066"
                        />
                        <path
                            d="M57.155 24.0625V47.1151L35.8848 35.589L57.155 24.0625Z"
                            fill="#FF9A0020"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={width}
                        height={height}
                        viewBox="0 0 72 72"
                        fill="none">
                        <rect width="72" height="72" rx="16" fill="#FF9A0033" />
                        <path
                            d="M36 17C30.9609 17 26.1282 19.0018 22.565 22.565C19.0018 26.1282 17 30.9609 17 36C17 41.0391 19.0018 45.8718 22.565 49.435C26.1282 52.9982 30.9609 55 36 55L36 36L36 17Z"
                            fill="#FF9A00"
                        />
                        <path
                            d="M36 55C41.0391 55 45.8718 52.9982 49.435 49.435C52.9982 45.8718 55 41.0391 55 36C55 30.9609 52.9982 26.1282 49.435 22.565C45.8718 19.0018 41.0391 17 36 17L36 36L36 55Z"
                            fill="#FFFBF5"
                        />
                    </svg>
                )}
            </>
        );
    },
);
