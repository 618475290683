import Background from './background.webp';
import Logo from './focus_logo.png';
import Fire from './Fire_svg.svg';
import Email from './email_svg.svg';
import Lock from './lock_svg.svg';
import Facebook from './facebook.svg';
import Google from './google.svg';
import ChangeEmail from './change_email.svg';
import Think from './think.png';
import Key from './key.png';
import Success from './success.png';
import HomeImg1 from './home_img_1.png';
import HomeImg2 from './home_img_2.png';
import HomeImg3 from './round_pattern.png';
import King from './king.png';
import Account from './account.svg';
import ArrowRight from './arrow-right.svg';
import EmailBox from './email_box.png';
import Clock from './clock.svg';
import Camera from './camera.png';
import Smile from './smile.png';
import User from './user.png';
import GreenMark from './green-mark.png';
import MoonPng from './moon2.png';
import SunPng from './sun 2.png';
import Cover from './cover.png';
import CompanyLogo from './logo-company.webp';
import KeyIcon from './3dKey.webp';
import LightBackgorund from './light_orange_backgorund.png';
import Cowerkers from './cowarkers.png';
import FocusLanding from './focus.png';
import Celebrate from './celebrate.png';
import EmailVerified from './email-verified.png';
import He3d from './he3d.png';
import Her3d from './her3d.png';
import Clock3d from './clock3d.png';
import Goal3d from './goal3d.png';
import WireFrame from './carousel/wire_frame.png';
import VideoCall from './carousel/video_call.png';
import ReportPartner from './carousel/report-partner.png';
import FavouritePartners from './carousel/favourite-partners.png';
import BookSession from './carousel/book_session.png';
import BeforeSession from './carousel/before_session.png';
import RatePartner from './carousel/rate-partner.png';
import MobileVideoCall from './carousel/mobile_video_call.png';
import MobileReportPartner from './carousel/mobile_report-partner.png';
import MobileFavouritePartners from './carousel/mobile_favourite-partners.png';
import MobileBookSession from './carousel/mobile_book_session.png';
import MobileBeforeSession from './carousel/mobile_before_session.png';
import MobileRatePartner from './carousel/mobile_rate-partner.png';
import SessionGoal from './carousel/session_goal.png';
import UserCard from './carousel/user_card.png';
import EarthGlobal from './earth_globe.png';
import Language from './language.png';
import BlurImg from './blur-img.jpeg';
import LayoutGrid from './layout-grid.png';
import LayoutSpeaker from './layout-speaker.png';
import PublicRoom from './public-room.png'
import MasterCard from './mastercard.png'
import Gift from './gift.png'

export const Images = {
    background: Background,
    logo: Logo,
    fire: Fire,
    email: Email,
    lock: Lock,
    think: Think,
    key: Key,
    success: Success,
    facebook: Facebook,
    google: Google,
    change_email: ChangeEmail,
    home_img_1: HomeImg1,
    home_img_2: HomeImg2,
    home_img_3: HomeImg3,
    king: King,
    account: Account,
    arrow_right: ArrowRight,
    emailbox: EmailBox,
    clock: Clock,
    smile: Smile,
    camera: Camera,
    user: User,
    greenMark: GreenMark,
    moonpng: MoonPng,
    sunpng: SunPng,
    cover: Cover,
    CompanyLogo: CompanyLogo,
    Key: KeyIcon,
    Cowerkers,
    LightBackgorund,
    FocusLanding,
    Celebrate,
    EmailVerified,
    He3d,
    Her3d,
    Clock3d,
    Goal3d,
    WireFrame,
    VideoCall,
    ReportPartner,
    FavouritePartners,
    BookSession,
    BeforeSession,
    RatePartner,
    MobileVideoCall,
    MobileReportPartner,
    MobileFavouritePartners,
    MobileBookSession,
    MobileBeforeSession,
    MobileRatePartner,
    SessionGoal,
    UserCard,
    EarthGlobal,
    Language,
    BlurImg,
    LayoutGrid,
    LayoutSpeaker,
    PublicRoom,
    MasterCard,
    Gift
};
