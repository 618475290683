import {
    ISetFocusSessionsAction,
    ISetFocusSessionsStatsAction,
    ISetFocusSessionsMyFocusAction,
    ISetFocusSessionsLeaderBoardAction,
    ISetTodayFocusSessionsAction,
    ISetFocusSessionsByDateAction,
    ISetFocusSessionsMeAction,
    ISetEditingFocusSessionsAction,
    ISetFocusSessionsMePastAction,
    ISetFocusSession,
    IOpenFocusSessionModalAction,
    ISetMyFocusSessionsByDateAction,
    ISetOpenDeleteFocusSessionModalAction,
    ISetFocusSessionsMySessionsCurrentDateAction,
    IOpenFocusSessionsReviewSessionsGuidelinesAction,
    IChooseRepeatSessionUpdateTypeAction,
    ISetFocusSessionsFavouritesAction,
    ISetIsCalendarFullscreen,
    ISetFocusSessionAction,
    ISetCalendarFocusSessionAction
} from 'models';

export enum EFocusSessionsTypes {
    SET_FOCUS_SESSIONS = 'SET/FOCUS/SESSIONS',
    POST_FOCUS_SESSIONS = 'POST/FOCUS/SESSIONS',
    GET_FOCUS_SESSIONS_MY_FOCUS_SAGA = 'GET/FOCUS/SESSIONS/MY/FOCUS/SAGA',
    GET_FOCUS_SESSIONS_STATS_SAGA = 'GET/FOCUS/SESSIONS/STATS/SAGA',
    GET_FOCUS_SESSIONS_LEADERBOARD_SAGA = 'GET/FOCUS/SESSIONS/LEADERBOARD/SAGA',
    SET_FOCUS_SESSIONS_LEADERBOARD = 'SET/FOCUS/SESSIONS/LEADERBOARD',
    SET_FOCUS_SESSIONS_STATS = 'SET/FOCUS/SESSIONS/STATS',
    SET_FOCUS_SESSIONS_MY_FOCUS = 'SET/FOCUS/SESSIONS/MY/FOCUS',
    GET_TODAY_FOCUS_SESSIONS_SAGA = 'GET/TODAY/FOCUS/SESSIONS/SAGA',
    SET_TODAY_FOCUS_SESSIONS = 'SET/TODAY/FOCUS/SESSIONS',
    GET_FOCUS_SESSIONS_BY_DATE_SAGA = 'GET/FOCUS/SESSIONS/BY/DATE/SAGA',
    SET_FOCUS_SESSIONS_BY_DATE = 'SET/FOCUS/SESSIONS/BY/DATE',
    PATCH_FOCUS_SESSIONS_MY_FOCUS_SAGA = 'PATCH/FOCUS/SESSIONS/MY/FOCUS/SAGA',
    GET_FOCUS_SESSIONS_ME_SAGA = 'GET/FOCUS/SESSIONS/ME/SAGA',
    SET_FOCUS_SESSIONS_ME = 'SET/FOCUS/SESSIONS/ME',
    POST_BOOK_FOCUS_SESSION_SAGA = 'POST_BOOK_FOCUS_SESSION_SAGA',
    PATCH_BOOKED_FOCUS_SESSION_SAGA = 'PATCH/BOOKED/FOCUS/SESSION/SAGA',
    SET_EDITING_FOCUS_SESSION = 'SET/EDITING/FOCUS/SESSION',
    DELETE_FOCUS_SESSION_SAGA = 'DELETE/FOCUS/SESSION/SAGA',
    GET_FOCUS_SESSIONS_ME_PAST_SAGA = 'GET/FOCUS/SESSIONS/ME/PAST/SAGA',
    SET_FOCUS_SESSIONS_ME_PAST = 'SET/FOCUS/SESSIONS/ME/PAST',
    JOIN_FOCUS_SESSION_SAGA = 'JOIN/FOCUS/SESSION/SAGA',
    GET_FOCUS_SESSION_SAGA = 'GET/FOCUS/SESSION/SAGA',
    SET_FOCUS_SESSION = 'SET/FOCUS/SESSION',
    POST_FOCUS_SESSION_RATE_USER_SAGA = 'POST/FOCUS/SESSION/RATE/USER/SAGA',
    POST_ADD_USER_TO_FAVOURITE_LIST_SAGA = 'POST/ADD/USER/TO/FAVOURITE/LIST/SAGA',
    POST_FOCUS_SESSION_REPORT_USER_SAGA = 'POST/FOCUS/SESSION/REPORT_USER/SAGA',
    POST_FOCUS_SESSION_LEAVE_SAGA = 'POST/FOCUS/SESSION/LEAVE/SAGA',
    OPEN_FOCUS_SESSION_MODAL = 'OPEN/FOCUS/SESSION/MODAL',
    GET_ME_FOCUS_SESSIONS_BY_DATE_SAGA = 'GET/ME/FOCUS/SESSIONS/BY/DATE/SAGA',
    SET_MY_FOCUS_SESSIONS_BY_DATE = 'SET/MY/FOCUS/SESSIONS/BY/DATE',
    OPEN_DELETE_FOCUS_SESSION_MODAL = 'OPEN/DELETE/FOCUS/SESSION/MODAL',
    GET_FOCUS_SESSION_BY_DATE_RANGE_SAGA = 'GET/FOCUS/SESSIONS/BY/DATE/RANGE/SAGA',
    WS_FOCUS_SESSION_UPDATED_EVENT_SAGA_ACTION = 'WS/FOCUS/SESSION/UPDATED/EVENT/SAGA/ACTION',
    WS_FOCUS_SESSION_DELETED_EVENT_SAGA_ACTION = 'WS/FOCUS/SESSION/DELETED/EVENT/SAGA/ACTION',
    FOCUS_SESSIONS_SET_MY_SESSIONS_CURRENT_DATE = 'FOCUS/SESSIONS/SET/MY/SESSIONS/CURRENT/DATE',
    FOCUS_SESSIONS_OPEN_REVIEW_SESSIONS_GUIDELINES = 'FOCUS/SESSIONS/OPEN/REVIEW/SESSIONS/GUIDELINES',
    CHOOSE_REPEAT_SESSION_UPDATE_TYPE = 'CHOOSE/REPEAT/SESSION/UPDATE/TYPE',
    GET_FOCUS_SESSIONS_FAVOURITES_SAGA_ACTION = 'GET/FOCUS/SESSIONS/FAVOURITES/SAGA/ACTION',
    SET_FOCUS_SESSIONS_FAVOURITES_ACTION = 'SET/FOCUS/SESSIONS/FAVOURITES/ACTION',
    SET_IS_CALENDAR_FULLSCREEN_ACTION = 'SET/IS/CALENDAR/FULLSCREEN/ACTION',
    SET_FOCUS_SESSION_ACTION = 'SET/FOCUS/SESSION/ACTION',
    SET_CALENDAR_FOCUS_SESSION_ACTION = 'SET/CALENDAR/FOCUS/SESSION/ACTION'
}

export interface IFocusSessionParticipant {
    id: number;
    first_name: string;
    avatar: string;
    rating: number;
    completed_sessions: number;
    last_name_initial: string;
    profile_url: string;
}

export interface IRatings {
    rated_by?: number;
    rated_user?: number;
    rating?: number;
}

interface IFocusSessionTitle {
    [key: string]: string;
}

export interface ISession {
    id: number;
    name: string;
    notes: string;
    start_at: Date;
    end_at: Date;
    updated_at: Date;
    updated_by: any;
    created_at: Date;
    duration: 30 | 45 | 60;
    created_by: number;
    ratings: IRatings[];
    participants: IFocusSessionParticipant[];
    colour: { [key: string]: string };
    focus_session_repeat_parent: number | null;
    title: IFocusSessionTitle;
    sessionTitle?: IFocusSessionTitle;
    repeat_config:
    | {
        active: boolean;
        children: string[];
        created_at: Date;
        ends_after: Date | null;
        ends_after_occurrences: number | null;
        ends_never: boolean;
        id: number;
        parent_session: number;
        repeat_frequency: 'DAILY' | 'WEEKDAY' | 'WEEKEND' | 'WEEKLY';
        updated_at: Date;
    }
    | undefined;
    user_group_id: string
}

export interface IFocusSessions {
    meta?: {
        total: number;
        next?: string;
        previous?: string;
        per_page: number;
        page: number;
    };
    results: ISession[];
}

export interface IFocusSessionsStats {
    sessions: {
        total: number;
        cancelled: number;
        no_show: number;
    };
    timeliness_score: string;
    weekly_streak: number;
    monthly_streak: number;
}

export interface IMyLeaderBoard {
    week: number;
    month: number;
    all_time: number;
}
export interface ILeaderBoardOthers {
    id: number;
    name: string;
    avatar: string;
    total: number;
    profile_url: string;
}

export interface ILeaderBoardMeOverall {
    week: number;
    month: number;
    all_time: number;
}

export interface ILeaderBoardMe {
    session_total: ILeaderBoardMeOverall;
    place: ILeaderBoardMeOverall;
}

export interface ILeaderBoard {
    me: ILeaderBoardMe;
    leaderboard: {
        week: ILeaderBoardOthers[];
        month: ILeaderBoardOthers[];
        all_time: ILeaderBoardOthers[];
    };
}

export interface IFocusSessionsState {
    calendar: ISession[] | undefined;
    sessions: IFocusSessions | undefined;
    calendarCurrentDate: {
        mySessions: Date;
    };
    pastSessions: IFocusSessions | undefined;
    favouritesSessions: IFocusSessions | undefined;
    mySessionsByDate: ISession[] | undefined;
    editingFocusSession?: ISession;
    stats?: IFocusSessionsStats;
    focus?: string;
    leaderBoard?: ILeaderBoard;
    session?: ISession;
    isFocusSessionModalOpen: boolean;
    isDeleteFocusSessionModalOpen: boolean;
    isReviewSessionGuidelinesOpen: boolean;
    chooseRepeatSessionUpdateType: {
        open: boolean;
        type: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS';
        sessionData?: any;
    };
    isCalendarFullscreen: boolean;
}

export type TFocusSessionsActionTypes =
    | ISetFocusSessionsAction
    | ISetFocusSessionsStatsAction
    | ISetFocusSessionsMyFocusAction
    | ISetFocusSessionsLeaderBoardAction
    | ISetTodayFocusSessionsAction
    | ISetFocusSessionsByDateAction
    | ISetFocusSessionsMeAction
    | ISetEditingFocusSessionsAction
    | ISetFocusSessionsMePastAction
    | ISetFocusSession
    | IOpenFocusSessionModalAction
    | ISetMyFocusSessionsByDateAction
    | ISetOpenDeleteFocusSessionModalAction
    | ISetFocusSessionsMySessionsCurrentDateAction
    | IOpenFocusSessionsReviewSessionsGuidelinesAction
    | IChooseRepeatSessionUpdateTypeAction
    | ISetFocusSessionsFavouritesAction
    | ISetIsCalendarFullscreen
    | ISetFocusSessionAction
    | ISetCalendarFocusSessionAction;
