import { IRatings } from '../../../models/focusSessions/types';
import {
    EFocusSessionsTypes,
    IFocusSessions,
    IFocusSessionsStats,
    ILeaderBoard,
    ISession,
} from 'models';

export const setFocusSessionsAction = (payload: IFocusSessions) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS,
    payload,
});

export const setFocusSessionsStatsAction = (payload: IFocusSessionsStats) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_STATS,
    payload,
});

export const getFocusSessionsMyFocusAction = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_MY_FOCUS_SAGA,
    payload,
});

export const patchFocusSessionsMyFocusSaga = (payload: {
    myFocus: string;
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.PATCH_FOCUS_SESSIONS_MY_FOCUS_SAGA,
    payload,
});

export const getFocusSessionsStatsAction = (payload: {
    callbackOnSuccess: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_STATS_SAGA,
    payload,
});

export const setFocusSessionsMyFocusAction = (payload: string) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_MY_FOCUS,
    payload,
});

export const getFocusSessionsLeaderBoardSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_LEADERBOARD_SAGA,
    payload,
});

export const setFocusSessionsLeaderBoardAction = (payload: ILeaderBoard) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_LEADERBOARD,
    payload,
});

export const getTodayFocusSessionsSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_TODAY_FOCUS_SESSIONS_SAGA,
    payload,
});

export const getFocusSessionsMeSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_ME_SAGA,
    payload,
});

export const getFocusSessionsFavouritesSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_FAVOURITES_SAGA_ACTION,
    payload,
});

export const setFocusSessionsFavouritesAction = (payload: {
    sessions: IFocusSessions;
    reset: boolean;
}) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_FAVOURITES_ACTION,
    payload,
});

export const getFocusSessionsMePastSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_ME_PAST_SAGA,
    payload,
});

export const setFocusSessionsMePastAction = (payload: {
    sessions: IFocusSessions;
    reset: boolean;
}) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_ME_PAST,
    payload,
});

export const setTodayFocusSessionsAction = (payload: {
    sessions: IFocusSessions;
}) => ({
    type: EFocusSessionsTypes.SET_TODAY_FOCUS_SESSIONS,
    payload,
});

export const getTodayFocusSessionsByDateSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
    date: string;
    filterBy: 'All' | 'Favourites' | 'My Sessions' | '';
    duration: number[];
    groups: number[]
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSIONS_BY_DATE_SAGA,
    payload,
});

export const setFocusSessionsByDateAction = (payload: {
    calendar: ISession[];
}) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_BY_DATE,
    payload,
});

export const setFocusSessionsMeAction = (payload: {
    sessions: IFocusSessions;
    reset: boolean;
}) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSIONS_ME,
    payload,
});

export const postBookFocusSessionSaga = (payload: {
    name: string;
    title: string;
    notes: string;
    start_at: string;
    duration: 30 | 45 | 60;
    callbackOnSuccess: () => void;
    sessionRepeat: '' | 'DAILY' | 'WEEKDAY' | 'WEEKEND' | 'WEEKLY';
    sessionEndOn: Date | null;
    sessionEndAfter: string | null;
    sessionEndNever: boolean;
    callbackOnError?: ({ message }: { message: string }) => void;
    byDate: boolean; // * Used to update different reducers
    colour?: string
    groups?: string
}) => ({
    type: EFocusSessionsTypes.POST_BOOK_FOCUS_SESSION_SAGA,
    payload,
});

export const patchBookedFocusSessionSaga = (payload: {
    id: number;
    name: string;
    start_at: string;
    title: string;
    notes: string;
    duration: 30 | 45 | 60;
    sessionRepeat?: '' | 'DAILY' | 'WEEKDAY' | 'WEEKEND' | 'WEEKLY';
    sessionEndOn?: Date | null;
    sessionEndAfter?: string | null;
    sessionEndNever?: boolean;
    callbackOnSuccess: () => void;
    callbackOnError?: ({ message }: { message: string }) => void;
    byDate?: boolean; // * Used to update different reducers
    updateType: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS';
    colour?: string
    groups?: string | null
}) => ({
    type: EFocusSessionsTypes.PATCH_BOOKED_FOCUS_SESSION_SAGA,
    payload,
});

export const setEditingFocusSession = (payload: {
    session: Partial<ISession> | undefined;
}) => ({
    type: EFocusSessionsTypes.SET_EDITING_FOCUS_SESSION,
    payload,
});

export const setFocusSessionAction2 = (
    payload: { session: ISession; }
) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSION_ACTION,
    payload,
});

export const setCalendarFocusSessionAction = (
    payload: { session: ISession; }
) => ({
    type: EFocusSessionsTypes.SET_CALENDAR_FOCUS_SESSION_ACTION,
    payload,
});

export const deleteFocusSessionSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError: ({ message }: { message: string }) => void;
    byDate?: boolean;
}) => ({
    type: EFocusSessionsTypes.DELETE_FOCUS_SESSION_SAGA,
    payload,
});

export const joinFocusSessionSaga = (payload: {
    sessionId: number;
    callbackOnSuccess: ({ data }: { data: IFocusSessions }) => void;
    callbackOnError: ({ message }: { message: string }) => void;
    byDate: boolean;
    title?: string;
}) => ({
    type: EFocusSessionsTypes.JOIN_FOCUS_SESSION_SAGA,
    payload,
});

export const getFocusSessionSaga = (payload: {
    callbackOnSuccess?: (repeat_config: any, focusSession?: ISession) => void;
    callbackOnError?: ({ message }: { message: string }) => void;
    sessionId: number;
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSION_SAGA,
    payload,
});

export const setFocusSessionAction = (payload: {
    session: Partial<ISession> | undefined;
}) => ({
    type: EFocusSessionsTypes.SET_FOCUS_SESSION,
    payload,
});

export const postFocusSessionUserRatingSaga = (payload: {
    rating: IRatings;
    callbackOnSuccess: () => void;
    callbackOnError?: ({ message }: { message: string }) => void;
    sessionId: number
}) => ({
    type: EFocusSessionsTypes.POST_FOCUS_SESSION_RATE_USER_SAGA,
    payload,
});

export const postAddUserToFavouritesListSaga = (payload: {
    userId: number;
    callbackOnSuccess: () => void;
    callbackOnError?: ({ message }: { message: string }) => void;
    sessionId?: number;
}) => ({
    type: EFocusSessionsTypes.POST_ADD_USER_TO_FAVOURITE_LIST_SAGA,
    payload,
});

export const postFocusSessionReportUserSaga = (payload: {
    data: {
        info: string;
        subject: string;
    };
    sessionId: number;
    callbackOnSuccess?: () => void;
    callbackOnError?: ({ message }: { message: string }) => void;
}) => ({
    type: EFocusSessionsTypes.POST_FOCUS_SESSION_REPORT_USER_SAGA,
    payload,
});

export const postFocusSessionLeaveSaga = (payload: {
    sessionId: number;
    leaveFrom?: 'calendar' | 'upcoming';
    callbackOnSuccess: () => void;
    callbackOnError: ({ message }: { message: string }) => void;
    byDate?: boolean;
    deleteType: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS' | 'ALL_SESSIONS';
    repeatConfigId: number | undefined;
    message?: string;
}) => ({
    type: EFocusSessionsTypes.POST_FOCUS_SESSION_LEAVE_SAGA,
    payload,
});

export const openFocusSessionModalAction = (payload: boolean) => ({
    type: EFocusSessionsTypes.OPEN_FOCUS_SESSION_MODAL,
    payload,
});

export const getMyFocusSessionsByDateActionSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError?: () => void;
    date: string;
}) => ({
    type: EFocusSessionsTypes.GET_ME_FOCUS_SESSIONS_BY_DATE_SAGA,
    payload,
});

export const setMyFocusSessionsByDateAction = (payload: {
    mySessionsByDate: ISession[];
}) => ({
    type: EFocusSessionsTypes.SET_MY_FOCUS_SESSIONS_BY_DATE,
    payload,
});

export const openDeleteFocusSessionModalAction = (payload: boolean) => ({
    type: EFocusSessionsTypes.OPEN_DELETE_FOCUS_SESSION_MODAL,
    payload,
});

export const getFocusSessionsByDateRangeSaga = (payload: {
    startDate: string;
    endDate: string;
    calendar: 'others' | 'mine';
    callbackOnSuccess: () => void;
    callbackOnError: () => void;
    filterBy: 'All' | 'Favourites' | 'My Sessions' | '';
    duration?: number[];
    groups?: number[]
}) => ({
    type: EFocusSessionsTypes.GET_FOCUS_SESSION_BY_DATE_RANGE_SAGA,
    payload,
});

export const wsFocusSessionUpdatedAction = (payload: { session: ISession }) => ({
    type: EFocusSessionsTypes.WS_FOCUS_SESSION_UPDATED_EVENT_SAGA_ACTION,
    payload,
});

export const wsFocusSessionDeletedAction = (payload: { session: ISession }) => ({
    type: EFocusSessionsTypes.WS_FOCUS_SESSION_DELETED_EVENT_SAGA_ACTION,
    payload,
});

export const setFocusSessionsMySessionsCurrentDate = (payload: {
    date: Date;
}) => ({
    type: EFocusSessionsTypes.FOCUS_SESSIONS_SET_MY_SESSIONS_CURRENT_DATE,
    payload,
});

export const openFocusSessionReviewSessionsGuidelinesAction = (
    payload: boolean,
) => ({
    type: EFocusSessionsTypes.FOCUS_SESSIONS_OPEN_REVIEW_SESSIONS_GUIDELINES,
    payload,
});

export const setChooseRepeatSessionUpdateType = (payload: {
    open: boolean;
    type: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS';
    sessionData?: any;
}) => ({
    type: EFocusSessionsTypes.CHOOSE_REPEAT_SESSION_UPDATE_TYPE,
    payload,
});

export const setIsCalendarFullscreenAction = (payload: boolean) => ({
    type: EFocusSessionsTypes.SET_IS_CALENDAR_FULLSCREEN_ACTION,
    payload,
});
