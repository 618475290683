import {
    ISetTodoAction,
    IOpenCreateOrEditModalATodoAction,
    ISetEditableTodoAction,
    IFinishPomodoroRoundEarlyAction,
    IPickNewTaskWhileOneRunningAction,
    IPressPomodoroTimerAction,
    ISetPomodoroCurrentTaskAction,
    ISetNumberOfTodosFinishedAction,
    ISetActivePomodoroTabAction,
    ISetShowPomodoroWidget,
} from '.';

export enum ETodoTypes {
    GET_TODOS_SAGA = 'GET/TODOS/SAGA',
    SET_TODOS = 'SET/TODOS',
    POST_TODO_SAGA = 'POST/TODO/SAGA',
    OPEN_CREATE_OR_EDIT_MODAL = 'OPEN/CREATE/OR/EDIT/MODAL',
    SET_EDITABLE_TODO = 'SET/EDITABLE/TODO',
    PATCH_TODO_SAGA = 'PATCH/TODO/SAGA',
    DELETE_TODO_SAGA = 'DELETE/TODO/SAGA',
    CLEAR_ALL_TASKS_SAGA = 'CLEAR/ALL/TASKS/SAGA',
    CLEAR_ALL_COMPLETED_TASKS_SAGA = 'CLEAR/ALL/COMPLETED/TASKS/SAGA',
    FINISH_POMODORO_ROUND_EARLY = 'FINISH/POMODORO/ROUND/EARLY',
    PICK_NEW_TASK_WHILE_ONE_RUNNING = 'PICK/NEW/TASK/WHILE/ONE/RUNNING',
    PRESS_POMODORO_TIMER = 'PRESS/POMODORO/TIMER',
    SET_POMODORO_CURRENT_TASK = 'SET/POMODORO/CURRENT/TASK',
    SET_NUMBER_OF_TODOS_FINISHED = 'SET/NUMBER/OF/TODOS/FINISHED',
    SET_ACTIVE_POMODORO_TAB = 'SET/ACTIVE/POMODORO/TAB',
    SET_SHOW_POMODORO_WIDGET = 'SET/SHOW/POMODORO/WIDGET',
}

export interface ITodo {
    completed_at: Date | null;
    created_at: Date;
    created_by: number;
    due_by: Date;
    id: number;
    name: string;
    updated_at: Date;
    note: string | null;
    updated_by: number | null;
}

export interface ITodoState {
    data: ITodo[] | any | undefined;
    editableTodo: any | undefined;
    createOrEditModalOpened: 'CREATE' | 'EDIT' | '';
    finishPomodoroRoundEarly: boolean;
    pickNewTaskWhileOneRunning: {
        pickNew: boolean;
        task?: ITodo | undefined;
    };
    isPomodoroTimerOn: {
        running: boolean;
        timeLeft: {
            pomodoro: number;
            shortBreak: number;
            longBreak: number;
        };
    };
    currentTask: ITodo | undefined;
    noOfPomodorosFinished: number;
    activePomodoroTab: 'POMODORO' | 'SHORT_BREAK' | 'LONG_BREAK';
    showPomodoroWidget: boolean;
}

export type TTodoActionTypes =
    | ISetTodoAction
    | IOpenCreateOrEditModalATodoAction
    | ISetEditableTodoAction
    | IFinishPomodoroRoundEarlyAction
    | IPickNewTaskWhileOneRunningAction
    | IPressPomodoroTimerAction
    | ISetPomodoroCurrentTaskAction
    | ISetNumberOfTodosFinishedAction
    | ISetActivePomodoroTabAction
    | ISetShowPomodoroWidget;
