import {useCallback, memo} from 'react';
import Countdown from 'react-countdown';
import {createSelector} from 'reselect';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';

import {
    showToastAction,
    useAppDispatch,
    patchTodoActionSaga,
    setNumberOfTodosFinishedAction,
    pressPomodoroTimerAction,
    setActivePomodoroTabAction,
} from 'store';
import {selectPomodoroCurrentTask, useAppSelector} from 'store';
import {WidgetRenderTimer} from './components/widgetRenderTimer';

const stateSelectorHandle = createSelector(
    selectPomodoroCurrentTask,
    currentPomodoroTask => {
        return {
            currentPomodoroTask,
        };
    },
);

export const WidgetCounter = memo(
    ({
        refCallback,
        setTimerState,
        noOfPomodorosFinished,
        pomodoroActiveTab,
        pomodoroRunning,
    }: {
        refCallback: any;
        setTimerState: (state: 'PAUSE' | 'START') => void;
        noOfPomodorosFinished: number;
        pomodoroActiveTab: 'POMODORO' | 'SHORT_BREAK' | 'LONG_BREAK';
        pomodoroRunning: any;
    }) => {
        const dispatch = useAppDispatch();
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {currentPomodoroTask} = useAppSelector(stateSelector);
        const {t} = useTranslation();

        const onComplete = useCallback(() => {
            try {
                if (currentPomodoroTask)
                    dispatch(
                        patchTodoActionSaga({
                            todo: {
                                ...currentPomodoroTask,
                                completed_at: new Date(),
                            } as unknown as any,
                            directUpdate: true,
                        }),
                    );

                dispatch(
                    showToastAction({
                        horizontal: 'right',
                        vertical: 'top',
                        open: true,
                        title: t('pomodoro.congratulations'),
                        subTitle: `${t(
                            'pomodoro.youHaveFinishedOne',
                        )} ${pomodoroActiveTab
                            ?.toLowerCase()
                            .replace('_', ' ')}`,
                    }),
                );

                dispatch(
                    pressPomodoroTimerAction({
                        running: false,
                        timeLeft: {
                            pomodoro:
                                pomodoroActiveTab === 'POMODORO'
                                    ? 1500000
                                    : undefined,
                            shortBreak:
                                pomodoroActiveTab === 'SHORT_BREAK'
                                    ? 300000
                                    : undefined,
                            longBreak:
                                pomodoroActiveTab === 'LONG_BREAK'
                                    ? 900000
                                    : undefined,
                        },
                    }),
                );
                dispatch(
                    setActivePomodoroTabAction(
                        pomodoroActiveTab === 'POMODORO' &&
                            noOfPomodorosFinished === 3
                            ? 'LONG_BREAK'
                            : pomodoroActiveTab === 'POMODORO'
                            ? 'SHORT_BREAK'
                            : pomodoroActiveTab === 'SHORT_BREAK'
                            ? 'LONG_BREAK'
                            : 'POMODORO',
                    ),
                );

                dispatch(
                    setNumberOfTodosFinishedAction(
                        noOfPomodorosFinished < 4
                            ? noOfPomodorosFinished + 1
                            : 1,
                    ),
                );
                setTimerState('START');
            } catch (error) {
                console.log({error});
                Sentry.captureException(error);
            }
        }, [
            currentPomodoroTask,
            dispatch,
            noOfPomodorosFinished,
            pomodoroActiveTab,
            setTimerState,
            t,
        ]);

        const alarm = async () => {
            try {
                const path =
                    require('assets/audio/bedside-clock-alarm.mp3').default;
                const audio = new Audio(path);
                audio.muted = false;
                await audio.play();
            } catch (err) {
                console.log({err});
            }
        };

        const renderer = useCallback(({minutes, seconds, completed}) => {
            if (completed) alarm();

            return (
                <WidgetRenderTimer
                    completed={completed}
                    minutes={minutes}
                    seconds={seconds}
                />
            );
        }, []);

        return (
            <Countdown
                date={
                    pomodoroActiveTab === 'POMODORO'
                        ? pomodoroRunning?.timeLeft?.pomodoro
                            ? Date.now() + pomodoroRunning?.timeLeft?.pomodoro
                            : Date.now() + 1500000
                        : pomodoroActiveTab === 'SHORT_BREAK'
                        ? pomodoroRunning?.timeLeft?.shortBreak
                            ? Date.now() + pomodoroRunning?.timeLeft?.shortBreak
                            : Date.now() + 300000
                        : pomodoroRunning?.timeLeft?.longBreak
                        ? Date.now() + pomodoroRunning?.timeLeft?.longBreak
                        : Date.now() + 900000
                }
                autoStart={false}
                onComplete={onComplete}
                // key={
                //     pomodoroActiveTab === 'POMODORO'
                //         ? pomodoroRunning?.timeLeft?.pomodoro
                //             ? (
                //                   Date.now() +
                //                   pomodoroRunning?.timeLeft?.pomodoro
                //               ).toString()
                //             : (Date.now() + 1500000).toString()
                //         : pomodoroActiveTab === 'SHORT_BREAK'
                //         ? pomodoroRunning?.timeLeft?.shortBreak
                //             ? (
                //                   Date.now() +
                //                   pomodoroRunning?.timeLeft?.shortBreak
                //               ).toString()
                //             : (Date.now() + 300000).toString()
                //         : pomodoroRunning?.timeLeft?.longBreak
                //         ? (
                //               Date.now() + pomodoroRunning?.timeLeft?.longBreak
                //           ).toString()
                //         : (Date.now() + 900000).toString()
                // }
                ref={refCallback}
                onStart={() => {
                    setTimerState('PAUSE');
                }}
                onPause={() => {
                    setTimerState('START');
                }}
                renderer={renderer}
            />
        );
    },
);
